import { InlineWidget } from 'react-calendly';
import { useState } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import Config from '../../../config/Config';
import OnboardingCard from '../OnboardingCard';
import Modal from '../../Modal';
import Button from '../../Button';

const staticContent = {
  title: 'Download COLLECT',
  description:
    'We built a marketplace to make collecting sneakers  more fun. COLLECT is the marketplace where you can buy, sell and trade all in one place with the lowest fees. Premium members pay no fees.',
};
const OneOnOneComponent = () => {
  const [openCalendlyModal, setOpenCalendlyModal] = useState(false);
  const handleOpenCalendlyModal = () => setOpenCalendlyModal(true);
  const closeCalendlyModal = () => {
    setOpenCalendlyModal(false);
  };
  const isMobile = useMediaQuery('(max-width: 768px)');
  const leftPanel = 'one-on-one';
  const rightIcon = (
    <div
      className={`flex rounded-custom bg-black justify-center items-center ${
        isMobile ? 'w-12 h-12' : 'w-20 h-20'
      }`}
    >
      <embed
        src="/icons/collect.svg"
        width={isMobile ? '40' : '60'}
        height={isMobile ? '40' : '60'}
        tabIndex={-1}
      />
    </div>
  );

  const rightButton = (
    <Button
      btnContext="Learn More"
      canActivate
      textSize="text-sm"
      handleClick={handleOpenCalendlyModal}
    />
  );
  const content = {
    ...staticContent,
    leftPanel,
    rightIcon,
    rightButtons: [rightButton],
  };

  return (
    <>
      <OnboardingCard onboardingCardContent={content} />
      <Modal openModal={openCalendlyModal} handleClose={closeCalendlyModal} overrideInternalModal>
        <InlineWidget
          styles={{
            width: '90vw',
            maxWidth: '650px',
            height: isMobile ? '75vh' : '90vh',
            borderRadius: '.75rem',
          }}
          url={Config.COLLECT_PAGE_URL}
        />
      </Modal>
    </>
  );
};
const OneOnOne = {
  component: OneOnOneComponent,
  staticContent,
};
export default OneOnOne;
